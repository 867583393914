<template>
  <div class="flex-shrink-0 flex-grow-1 p-md-6 py-sm-3 px-sm-0">
    <div class="mw-100" style="width: 26.25rem; margin:0 auto;">
      <div class="card text-center">
        <div class="card-body mx-6 mb-6 mt-5 p-0">
          
          <h1 class="card-title mb-5">Reset Password</h1>

          <i>If you are an existing user and have forgotten
          your password, enter your email address
          below and request a reset code. A code will be
          emailed to you with a link to reset your password.</i>
          <br><br>

          <form id="requestPasswordReset" class="text-left mw-1000" autocomplete="off" @submit.prevent="requestPasswordReset">

            <div class="col-sm-12">
              <div class="form-group">
                <label for="email" class="sr-only">Email</label>
                <input 
                  type="email" 
                  id="email" 
                  placeholder="Email" 
                  v-model="$v.form.email.$model" 
                  class="form-control form-control-lg mb-5 text-center"
                  :class="{
                    'is-valid': !$v.form.email.$error && $v.form.email.$dirty,
                    'is-invalid': $v.form.email.$error
                  }"
                />
              </div>
            </div>

            <button type="submit" :disabled="$v.form.$invalid" class="btn btn-primary btn-block">
              Request Password Reset Code
            </button>
          </form>
        </div>
      </div>
    </div>
    <br><br><br><br><br><br><br><br><br><br><br><br><br><br><br>
  </div>
</template>

<script>
import { pcMixin } from '@/mixins/pcMixin.js';
import { apiMixin } from '@/mixins/apiMixin.js';

import { email } from 'vuelidate/lib/validators';
import { required } from 'vuelidate/lib/validators';

import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY });

export default {
  name: 'RequestPasswordResetView',

  mixins: [ pcMixin, apiMixin ],

  data() {
    return {
      form: {
        email: null,
        resetcode: null
      }
    }
  },

  validations: {
    form: {
      email: {
        email,
        required
      }
    }
  },

  methods: {

    async requestPasswordReset() {
      this.pcSpinnerShow();

      // Wait until recaptcha has been loaded.
      await this.$recaptchaLoaded()

      // Execute reCAPTCHA with action "requestpwreset".
      const token = await this.$recaptcha('requestpwreset')

      this.apiRequestPwReset(this.form.email, token)
      .then(() => {
        this.pcModal("An email has been sent to the specified email address with a link and a passcode to change your password")
        .then(() => { this.$router.push({ name: 'ResetPasswordRoute' }) })
        .catch(() => {})
      })
      .catch(() => {
        this.pcModal("Internal error").catch(() => {});
      })
      .finally(() => { this.pcSpinnerHide(); });
    }
  }
}
</script>

<style scoped>
  .wide {
    width: 300px;
  }
</style>
